<template>
  <v-sheet class="transparent">
    <v-row class="transparent">
      <v-col
        v-if="useMonth"
        cols="12"
        sm="6"
        lg
      >
        <!-- MENU SELECTOR: MONTH -->
        <v-menu
          min-width="auto"
          offset-y
          v-model="monthMenu"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="selectedMonth"
              clearable
              :label="$t('month') + ':'"
              class="white"
              hide-details
              readonly
              v-bind="attrs"
              dense
              v-on="on"
              outlined
              @click:clear="selectedMonth = null"
            />
          </template>
          <v-date-picker
            v-model="selectedMonth"
            type="month"
            @change="monthMenu = false"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="useDate"
        cols="12"
        sm="6"
        lg
      >
        <!-- MENU SELECTOR: DAY -> fromDate --->
        <v-menu
          min-width="auto"
          offset-y
          v-model="fromDateMenu"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFormatted(fromDate)"
              :label="$t('from') + ':'"
              class="white"
              readonly
              v-bind="attrs"
              dense
              hide-details
              outlined
              v-on="on"
              @click:clear="fromDate = null"
            />
          </template>
          <v-date-picker
            v-model="fromDate"
            @change="fromDateMenu = false"
            style="height: 425px;"
          />
        </v-menu>
      </v-col>
      <!-- MENU SELECTOR: DAY -> toDate --->
      <v-col
        v-if="useDate"
        cols="12"
        sm="6"
        lg
      >
        <v-menu
          min-width="auto"
          offset-y
          v-model="toDateMenu"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFormatted(toDate)"
              :label="$t('to') + ':'"
              class="white"
              hide-details
              readonly
              v-bind="attrs"
              dense
              v-on="on"
              outlined
              @click:clear="toDate = null"
            />
          </template>
          <v-date-picker
            v-model="toDate"
            @change="toDateMenu = false"
            style="height: 425px;"
          />
        </v-menu>
      </v-col>
      <!-- TIME SELECTOR: HH:MM -> dayStartsAt --->
      <v-col
        v-if="useDayStartsAt"
        cols="12"
        sm="6"
        lg
      >
        <TimeSelectorV2
          initial-time="00:00"
          label="Start Time"
          :dense="true"
          combobox-class="white"
          @selectTime="selectDayStartsAt"
        />
      </v-col>
      <!-- TIME SELECTOR: HH:MM -> dayEndsAt --->
      <v-col
        v-if="useDayEndsAt"
        cols="12"
        sm="6"
        lg
      >
        <TimeSelectorV2
          initial-time="23:59"
          label="End Time"
          :dense="true"
          combobox-class="white"
          @selectTime="selectDayEndsAt"
        />
      </v-col>
      <v-col
        v-if="useStaff"
        cols="12"
        sm="6"
        lg
      >
        <v-select
          item-text="address.name_line"
          item-value="uuid"
          outlined
          v-model="selectedStaffID"
          :items="staff"
          class="white"
          dense
          hide-details
          clearable
          :label="$t('staff')"
        />
      </v-col>
      <v-col
        v-if="useLocations"
        cols="12"
        sm="6"
        lg
      >
        <v-select
          item-text="label"
          item-value="uuid"
          clearable
          outlined
          v-model="selectedLocations"
          :items="locations"
          class="white"
          dense
          hide-details
          :label="$t('location')"
          multiple
        />
      </v-col>
      <v-col
        v-if="useChannels"
        cols="12"
        sm="6"
        lg
      >
        <v-select
          v-if="useChannels"
          clearable
          outlined
          item-text="label"
          item-value="uuid"
          v-model="selectedChannels"
          :items="channels"
          class="white"
          dense
          hide-details
          :label="$t('channel')"
          multiple
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

import moment from 'moment'
import { format, parseISO } from 'date-fns'
import TimeSelectorV2 from '@/components/common/TimeSelectorV2'

export default {
	/**
	 * Checks props to see which filters to display.
	 * Returns all dates i.e. month, startDate and fromDate as the start of day/month in milliseconds.
	 * When using date calendars with useDate prop the time between dates will default to a week.
	 * This is intended to reduce initial load times for large queries.
	 */
  name: 'FilterHeader',
	components: {
		TimeSelectorV2
	},
	/**
	 * @props Should perhaps change to always request default values and replace booleans with check.
	 *
	 */
	props: {
		useMonth: {
			type: Boolean,
			default: false
		},
		defaultMonth: {
			type: Date,
			default() {
				return new Date()
			}
		},
    initializeTimeToToday: {
      type: Boolean,
      default: false
    },
		useDate: {
			type: Boolean,
			default: false
		},
		useLocations: {
			type: Boolean,
			default: false
		},
		useChannels: {
			type: Boolean,
			default: false
		},
		useStaff: {
			type: Boolean,
			default: false
		},
		useDayStartsAt: {
			type: Boolean,
			default: false
		},
		useDayEndsAt: {
			type: Boolean,
			default: false
		}
	},
  data () {
    return {
			oneMinuteInMillis: 60000,
			oneHourInMillis: 3600000,
			oneDayInMillis: 86400000,
			monthMenu: false,
			selectedMonth: this.defaultMonth.toISOString().slice(0, 7),
      fromDateMenu: false,
			fromDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
			dayStartsAtInMillis: 0, // 00:00 AM in millis
			dayEndsAtInMillis: 86340000, // 23:59 AM in millis
      toDateMenu: false,
      toDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    	selectedTabs: [],
      selectedLocations: [],
      selectedChannels: [],
			selectedStaffID: '',
      locations: [],
      channels: [],
      staff: [],
			filters: [
				{
					key: 'monthPicker',
					label: this.$t('selectMonth') + ':',
					type: 'menu'
				},
				{
					key: 'dateFrom',
					label: this.$t('dateFromStartOf') + ':',
					type: 'menu'
				},
				{
					key: 'dateTo',
					label: this.$t('dateToBeginningOf') + ':',
					type: 'menu'
				},
				{
					key: 'locationsList',
					label: this.$t('dateFrom') + ':',
					type: 'list'
				},
				{
					key: 'channelsList',
					label: this.$t('dateFrom') + ':',
					type: 'list'
				},
			]
    }
  },
  created() {
    let lastWeek = new Date(new Date().getTime() - (this.oneDayInMillis * 7))
    this.fromDate = format(parseISO(lastWeek.toISOString()), 'yyyy-MM-dd')
		if(this.useLocations){
			for (const location in this.$store.state.locations) {
				this.locations.push(this.$store.state.locations[location])
			}
		}
		if(this.useChannels) {
			for (const channel in this.$store.state.channels) {
				this.channels.push(this.$store.state.channels[channel])
			}
		}
		if(this.useStaff) {
			if(this.$store.state.staff === null || this.$store.state.staff === undefined || this.$store.state.staff.length <= 0) {

				this.$store.dispatch('getStaff').then(() => {
					for (const curr in this.$store.state.staff) {
						this.staff.push(this.$store.state.staff[curr])
					}
				})
			}
			else {
				for (const curr in this.$store.state.staff) {
					this.staff.push(this.$store.state.staff[curr])
				}
			}
		}
  },
  methods: {
		selectDayStartsAt(value) {
			this.dayStartsAtInMillis = this.convertHoursMinutesToMillis(value)
		},
		selectDayEndsAt(value) {
			this.dayEndsAtInMillis = this.convertHoursMinutesToMillis(value)
		},
		convertHoursMinutesToMillis(value) {
			const hoursInNumberFormat = +value.split(':')[0]
			const minutesInNumberFormat = +value.split(':')[1]
			const hoursInMillis = hoursInNumberFormat * this.oneHourInMillis
			const minutesInMillis = minutesInNumberFormat * this.oneMinuteInMillis
			return hoursInMillis + minutesInMillis
		},
		refresh() {
			this.$store.dispatch('getInitialData')
		},
    returnFilterValues() {
			/*
			* almostTwelveHoursInMillis is subtracted from each date in order to get the start of the day.
			* This is due to the fact that Date.parse returns the date parsed at 12:00 AM
			*/
      let start = null
      let end = null

      if( this.useMonth ) {
        start = Date.parse(this.selectedMonth)
				start = new Date(start)
				start.setHours(0, 0, 0, 0)
				start = start.getTime()
        const date = new Date(start)
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1)
        end = endDate.getTime()
      }
      else {
        start = Date.parse(this.fromDate)
				start = new Date(start)
				start.setHours(0, 0, 0, 0)
				start = start.getTime()
        end = Date.parse(this.toDate) + this.oneDayInMillis
				end = new Date(end)
				end.setHours(0, 0, 0, 0)
				end = end.getTime()
      }

			if(!this.useDayStartsAt) {
				this.dayStartsAtInMillis = null
			}

			if(!this.useDayEndsAt) {
				this.dayEndsAtInMillis = null
			}

      this.$emit('newValues', {
				startTimeInMillis: start,
				endTimeInMillis: end,
				dayStartsAtInMillis: this.dayStartsAtInMillis,
				dayEndsAtInMillis: this.dayEndsAtInMillis,
        locations: this.selectedLocations,
        channels: this.selectedChannels,
				staff: this.selectedStaffID
      })
    },
    computedDateFormatted (value) {
      return value ? moment(value).format('ddd, MMM Do Y') : ''
    },
  },
	watch: {
		selectedMonth() {
			this.returnFilterValues()
		},
		fromDate() {
			this.returnFilterValues()
		},
		toDate() {
			this.returnFilterValues()
		},
		selectedLocations() {
			this.returnFilterValues()
		},
		selectedChannels() {
			this.returnFilterValues()
		},
		selectedStaffID() {
			this.returnFilterValues()
		},
		dayStartsAtInMillis() {
			this.returnFilterValues()
		},
		dayEndsAtInMillis() {
			this.returnFilterValues()
		},
	},
  computed: {
		profileStatsStartTimeInMillis() {
      return this.$store.state.profileStatsStartTimeInMillis
    },
    profileStatsEndTimeInMillis() {
      return this.$store.state.profileStatsEndTimeInMillis
    }
  },
  mounted() {
    if(this.profileStatsStartTimeInMillis) {
      const start = Number(this.profileStatsStartTimeInMillis)
      if(!isNaN(start)) {
        this.fromDate = format(parseISO(new Date(start).toISOString()), 'yyyy-MM-dd')
      }
    } else {
      let lastWeek = new Date(new Date().getTime() - (this.oneDayInMillis * 7))
      this.fromDate = format(parseISO(lastWeek.toISOString()), 'yyyy-MM-dd')
    }
    if(this.profileStatsEndTimeInMillis) {
      const end = Number(this.profileStatsEndTimeInMillis)
      if(!isNaN(end)) {
        this.toDate = format(parseISO(new Date(end).toISOString()), 'yyyy-MM-dd')
      }
    }

    if(this.initializeTimeToToday === true) {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() +1)
      const todayInMillis = today.getTime()
      let endOfDayInMillis = endOfDay.getTime() -1

			if(this.useDayEndsAt) {
				endOfDayInMillis += this.oneDayInMillis
			}

      this.fromDate = format(parseISO(new Date(todayInMillis).toISOString()), 'yyyy-MM-dd')
      this.toDate = format(parseISO(new Date(endOfDayInMillis).toISOString()), 'yyyy-MM-dd')

			if(!this.useDayStartsAt) {
				this.dayStartsAtInMillis = null
			}

			if(!this.useDayEndsAt) {
				this.dayEndsAtInMillis = null
			}
      this.$emit('newValues', {
				startTimeInMillis: todayInMillis,
				endTimeInMillis: endOfDayInMillis,
				dayStartsAtInMillis: this.dayStartsAtInMillis,
				dayEndsAtInMillis: this.dayEndsAtInMillis,
        locations: this.selectedLocations,
        channels: this.selectedChannels,
				staff: this.selectedStaffID
      })
    }
  }
}
</script>

<style scoped>

</style>
